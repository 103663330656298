<template>
      <div>
          <div v-if="this.$store.state.cart.length > 0 || this.$store.state.menuFixed.length > 0">
                <div class="carts-products-dishes-items">
                      <div class="carts-products-dishes-item" v-for="(item, index) in this.$store.state.cart" :key="index">
                              <div class="carts-products-thumb-img">
                                  <img style="width:100%; height:100%;" :src="item.img">
                              </div>
                            <div class="carts-products-info">
                                  <h4>{{item.name}}</h4>
                                  <h4 class="carts-products-qt"> {{item.quantity}} x €{{item.price}}</h4>
                                  <div class="carts-products-price-button-box">
                                        <div class="carts-products-info-button">
                                              <h4 class="carts-products-price">€{{ (item.quantity * item.price).toFixed(2)}}</h4>
                                        </div>
                                  </div>
                            </div>
                          <div class="clearfix"></div>  
                     </div>
                </div>
                <div style="margin-top:15px;">
                  <div v-if="this.$store.state.cart.length > 0">
                        <div class="cart-review-total-price clearfix">
                        <div class="wl-label">Subtotale:</div>
                        <div class="review-total-price"> €{{$store.getters.totalPrice}}</div>
                        </div>
                        <div class="cart-review-total-price clearfix">
                        <div class="wl-label">Sconto {{discountPercent}}%:</div>
                        <div class="review-total-price">- €{{discountPrice}}</div>
                        </div>
                  </div>
                  <div v-if="$store.getters.menuTotalPrice > 0" class="cart-review-total-price clearfix">
                       <div class="wl-label">{{$store.state.menuFixed.length}}x Menu Fisso:</div>
                       <div class="review-total-price"> €{{$store.getters.menuTotalPrice}}</div>  
                  </div>
                  <div v-if="notSupportDelivery != ''" style="clear:both; padding:10px; margin-bottom:20px; width:93%; background:orange;">{{notSupportDelivery}}</div>
                  <div v-if="serviceChoice == 1" class="cart-review-total-price clearfix">
                       <div class="wl-label">Costo di consegna:</div>
                       <div class="review-total-price"> €{{costOfShipping.toFixed(2)}}</div>
                  </div>
                  <div class="cart-review-total-price clearfix">
                       <div class="wl-label">Totale ordine:</div>
                       <div class="review-total-price"> €{{parseFloat(totalOrderPrice).toFixed(2)}}</div>
                  </div>
                </div>
          </div>  

          </div>
</template>
<script>
import Vue from "vue";
import { Dialog, Notify } from "vant";
Vue.use(Dialog, Notify);
export default {
      name: 'Cart',
      data(){
            return{

            }
      },
      props:['serviceChoice', 'options', 'costOfShipping', 'discountPrice', 'discountPercent',  'totalOrderPrice', 'notSupportDelivery'],
      computed:{
            orderDiscount(){
                  if(this.options){
                        if(this.serviceChoice == 1){
                              return this.options.delivery.discount
                        }else if(this.serviceChoice == 2){
                              return this.options.takeaway.discount
                        }else{
                              return ''
                        }
                  }
            },
            // priceDiscount(){
            //       let priceDiscount = 0.00
            //       if(this.options){
            //             if(this.serviceChoice == 1){
            //                   priceDiscount = this.$store.getters.totalPrice * this.options.delivery.discount / 100
            //                   return priceDiscount.toFixed(2)
            //             }else if(this.serviceChoice == 2){
            //                   priceDiscount = this.$store.getters.totalPrice * this.options.takeaway.discount / 100
            //             }else{
            //                   priceDiscount = 0.00
            //             }
            //       }
            //       return parseFloat(priceDiscount).toFixed(2)      
            // },
            // finalPrice(){
            //       return parseFloat(this.$store.getters.totalPrice - this.priceDiscount).toFixed(2)
            // }
      },
      created(){

      },
      methods:{

           
      }
}
</script>
<style>
.cart-list-container{
      max-width: 100%;
      width: 100%;
      height: 100%;
}
.cart-close-button{
      margin-bottom: 10px;
}
.cart-is-empty{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
}
.van-empty__description{
      width: 100%;
      text-align: center;
}
.carts-products-dishes-items{
      display: block;
      width: 100%;
}
.carts-products-dishes-item{
      margin: 15px 0;
      border-bottom:1px solid #e5e5e5e5;
      padding-bottom: 15px; 
      position: relative; 
}
.clearfix{
      clear: both;
}
.carts-products-delete-button{
      float: left;
      width: 50px;
      text-align: center;
      line-height: 80px;
}
.carts-products-thumb-img{
      width: 80px;
      height: 80px;
      float: left;
      margin-left:10px;
}
.carts-products-thumb-img img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
}
.carts-products-info{
    float: left;
    width: calc(100% - 150px);
    padding: 5px;
}

.carts-products-price-button-box{
      position: absolute;
      bottom: 10px;
      width: calc(100% - 100px);
}
.carts-products-info-button{
      width: 100%;
}
.carts-products-price{
      color: red;
      width: 100%;
      position: relative;
      text-align: right;
      right: 10px;
}
.review-total-price{
      text-align: right;
      padding-right: 15px;
}
.carts-products-qt{
      margin-top: 15px;
}
.cart-review-total-price .wl-label{
      font-size: 1.1em;
      font-weight: normal;
}
</style>
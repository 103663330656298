<template>
  <div class="checkout-container-wrapper" v-if="!isLoading">
    <van-nav-bar
      safe-area-inset-top
      :fixed="true"
      title="Cassa"
      left-text="Indietro"
      left-arrow
      @click-left="goMenuPage"
    />
    <div :safe-area-inset-top="true" class="checkout-container">
      <div class="checkout-wrapper">
        <van-form @submit="onSubmit">
        <div class="checkout-content">
          <div v-if="shop.options" class="checkout-content-box">
            <div class="checkout-services-wrapper">
              <div v-if="shop.options.delivery.is_active == 1"
                @click="serviceSelected(1)"
                class="checkout-services-box"
                :class="{ active: serviceChoice == 1 }"
              >
                <div class="service-icon">
                  <img src="../assets/img/delivery_icon.jpg" />
                </div>
                <h3>Consegna a domicilio</h3>
                <van-icon v-if="serviceChoice == 1" name="checked" />
              </div>
              <div
                @click="serviceSelected(2)"
                class="checkout-services-box"
                :class="{ active: serviceChoice == 2 }">
                <div class="service-icon">
                  <img src="../assets/img/takeaway_icon.jpg" />
                </div>
                <h3>Ritiro al ristorante</h3>
                  <van-icon v-if="serviceChoice == 2" name="checked" />
                  </div>
                </div>
            <div v-if="serviceChoice == 2">
              <van-cell title="Ritiro al ristorante" label="" icon="shop-o">
                <template #right-icon>
                  <van-icon name="location-o" class="search-icon" />
                </template>
                <template #label>
                  <div class="address-restaurant">
                    {{ shop.street + " " + shop.number + ", " + shop.city }}
                  </div>
                </template>
              </van-cell>
            </div>  
            <van-cell-group>
              <van-field v-model="info.name" required label="Nome" :rules="[{ required: true, message: 'Nome richiesta' }]" />
              <van-field v-model="info.phone" required label="N. cellulare" :rules="[{ required: true, message: 'N.cell richiesta' }]" />
              <van-field v-model="info.email" required label="E-mail" :rules="[{ required: true, message: 'Email richiesta' }]" />
              <van-field
                readonly
                clickable
                required
                v-model="info.hours"
                label="Oraria"
                :rules="[{ required: true, message: 'Oraria richiesta' }]"
                :placeholder="
                  serviceChoice == 1 ? 'Orario di Consegna' : 'Orario di Ritiro'
                "
                @click="showOpenHours = true"
              />
              <van-field
                v-model="info.note"
                rows="2"
                autosize
                label="Nota"
                type="textarea" placeholder="Nota per ordine"/>
            </van-cell-group>

            <div class="address-form" v-if="serviceChoice == 1">
              <h3 class="title-address">Indirizzo di consegna</h3>
              <van-cell-group>
                <van-field
                required
                readonly
                clickable
                v-model="address.street"
                :rules="[{ required: true, message: 'Via richiesta' }]"
                label="Via"
                @click="showDistanceCalculate = true"
              />
                <van-field v-model="address.number" required label="N. civico" :rules="[{ required: true, message: 'N. civico richiesta' }]" />
                <van-field v-model="address.intercom" label="Citofono" />
                <van-field v-model="address.zip_code" required label="Cap" :rules="[{ required: true, message: 'Cap richiesta' }]" />
                <van-field v-model="address.city" required label="Città" :rules="[{ required: true, message: 'Città richiesta' }]" />
              </van-cell-group>    
            </div>
            <van-cell-group>
                <van-field v-model="payment" required label="Pamagento" :rules="[{ required: true, message: 'Pagamento richiesta' }]">
                <template #input>
                  <van-radio-group v-if="shop.options" v-model="payment" direction="horizontal">
                    <van-radio v-if="shop.options.payment.stripe_is_active == 1" name="Carta">Carta</van-radio>
                    <van-radio v-if="shop.options.payment.cash_is_active == 1" name="contanti">contanti</van-radio>   
                  </van-radio-group>
                </template>
              </van-field>  
            </van-cell-group>
          </div>   
        </div>
        <div class="checkout-sidebar">
          <review-product 
            :discountPercent="discountPercent"
            :discountPrice="discountPrice"
            :serviceChoice="serviceChoice" 
            :options="shop.options" 
            :costOfShipping="costOfShipping"  
            :totalOrderPrice="totalOrderPrice"
            :notSupportDelivery="notSupport"
            />
          <van-button
            :disabled="disabledButton"
            class="send-order-button"
            native-type="submit"
            color="linear-gradient(to right, #f28e26,#fd644f)"
            size="large">{{buttonTextSubmit}}</van-button>
        </div>
        </van-form> 
      </div>
    </div>
    <van-popup :style="{maxWidth:'100%', width: '478px', minHeight:'220px', padding:'15px'}" round v-model="showOpenHours">
          <div v-if="shop.options">
            <open-hours :dayOpenHours="shop.options.open_hours" :order_interval_minutes="shop.options.order_time.order_interval_minutes" :order_before_minutes="shop.options.order_time.order_before_minutes" />
          </div>
    </van-popup>
    <van-popup v-if="showDistanceCalculate" closeable v-model="showDistanceCalculate" round :style="{width: '478px', maxWidth:'90%', minHeight:'460px'}">
      <calculate-distance />
    </van-popup>
  </div>
</template>
<script>
import { Dialog, Notify } from "vant";
import ReviewProduct from "@/components/checkout/ReviewProduct.vue";
import OpenHours from "@/components/checkout/OpenHours.vue";
import CalculateDistance from '@/components/checkout/CalculateDistance.vue'

export default {
  name: "CheckOut",
  components: {
    ReviewProduct,
    OpenHours,
    CalculateDistance
  },
  data() {
    return {
      shop: [],
      showOpenHours: false,
      showDistanceCalculate: false,
      serviceChoice: '1',
      disabledButton: false,
      payment: '',
      costOfShipping: 0,
      notSupport:'',
      isLoading: true,
      info: {
        name: "",
        phone: "",
        email: "",
        hours: "",
        note: "",
      },
      address: {
        street: "",
        number: "",
        intercom: "",
        zip_code: "",
        city: "",
      },
    };
  },
  computed:{
    spread(){
      let spread = this.shop.options.delivery.min_price - this.$store.getters.totalPrice   + this.calculationMinPrice
      return parseFloat(spread).toFixed(2);
      },
    calculationMinPrice(){
      let totalPrice = this.$store.getters.totalPrice
      let discount = this.shop.options.delivery.discount
    
      return parseFloat((totalPrice * discount / 100))
    },

    discountPrice(){
      return (this.$store.getters.totalPrice * this.discountPercent / 100).toFixed(2)
    },
    discountPercent(){
      var discountPercent = 0
      if(this.$store.state.cart.length > 0){
        if(this.shop.options){
          
          if(this.serviceChoice == 1){
            if(this.shop.options.delivery.is_multi_fee == 1){
              this.shop.options.delivery.multi_discount.forEach(item =>{
                if(parseFloat(this.$store.getters.totalPrice)  > parseFloat(item.import)){
                  discountPercent = item.discount
                }
              })
             
            }else{
              discountPercent = this.shop.options.delivery.discount
            }            
          }
          
          if(this.serviceChoice == 2){
            if(this.shop.options.takeaway.is_multi_fee == 1){
              this.shop.options.takeaway.multi_discount.forEach(item =>{
                if(parseFloat(this.$store.getters.totalPrice)  > parseFloat(item.import)){
                  discountPercent = item.discount
                }
              })     
            }else{
              discountPercent = this.shop.options.takeaway.discount
            } 
          }
          
        }
        }
        return discountPercent
      },
      totalOrderPrice(){
        return parseFloat(this.$store.getters.totalPrice) - parseFloat(this.discountPrice) + parseFloat(this.costOfShipping) + parseFloat(this.$store.getters.menuTotalPrice)
      },
    buttonTextSubmit(){
      return this.payment == 'Carta' ? 'Continua su Stripe' : 'Effettua ordine'
    }
  },
  created() {
    if(this.$store.state.cart.length == 0 && this.$store.state.menuFixed.length == 0){
      this.$router.push('/')
    }
    this.disabledButton = false
    this.fetchData();

  },
  methods: {
    fetchData() {
      this.axios.get("/webapi/shopInfo/getShopInfoOptions").then((res) => {
        this.shop = res.data.data;
        if(this.shop.options.delivery.is_active == 2){
          this.serviceChoice = '2'
        }
        if(this.shop.options.delivery.menu_support_delivery == 1 && this.$store.state.menuFixed.length > 0){
          this.isLoading = false 
          return
        }
        
        this.notSupport = this.shop.options.delivery.menu_support_delivery == 2 ? 'Menu fisso solo ritirare al ristorante':''
          
        if (this.serviceChoice == 1 && (this.$store.getters.totalPrice - this.calculationMinPrice) < (this.shop.options.delivery.min_price) ){
              this.notifyMinOder()
        }
        this.isLoading = false
      });
    },
    goMenuPage() {
      this.$router.push("/");
    },
    selectedTime(time){
          this.info.hours = time
          this.showOpenHours = false
    },
    serviceSelected(index) {
      this.serviceChoice = index;
      
      if(this.shop.options.delivery.menu_support_delivery == 1 && this.$store.state.menuFixed.length > 0){
              return
          }

      if (this.serviceChoice == 1 && (this.$store.getters.totalPrice - this.calculationMinPrice) < (this.shop.options.delivery.min_price) ){
              this.notifyMinOder()
      }
    },

    notifyMinOder() {
      Dialog.confirm({
        title: "Avviso",
        confirmButtonText: "Continua",
        cancelButtonText: "Menu",
        message:
          "consegna ordine minimo €"+this.shop.options.delivery.min_price+", mancano €"+ this.spread +", clicca il menù per raggiungere l'ordine minimo, oppure continua per ritiro al ristorante. " + this.notSupport,
      })
        .then(() => {
          this.serviceChoice = 2;
          return false;
        })
        .catch(() => {
          this.$router.push("/")
        });
    },

    updateAddress(address){
          this.address = address

          if(this.shop.options.delivery.is_multi_distance == 1){
              this.shop.options.delivery.multiRules.forEach(item =>{

                  if(address.distance <= (item.distance * 1000)){
                     this.costOfShipping = parseFloat(item.fee)                     
                  }
                 
              })
          }

          this.showDistanceCalculate = false
    },
    onSubmit(){
      var stripe = window.Stripe(this.shop.options.payment.pk);
      this.disabledButton = true
      let typeOrder = this.serviceChoice == 1 ? 'Consegna a domicilio' : 'Ritira al ristorante'
      let dishes = this.$store.state.cart
      let menufixed = this.$store.state.menuFixed
      let info = this.info
      let address = this.address
      let payment = this.payment
      let subTotal = this.$store.getters.totalPrice
      let menuprice = this.$store.getters.menuTotalPrice
      let discountPercent = this.discountPercent
      let discountPrice = this.discountPrice

      let shipping_fee = this.serviceChoice == 1 ? this.costOfShipping : 0.00
      let totalOrder = parseFloat(this.totalOrderPrice)

      var orderData = {
        type_order: typeOrder, email: info.email, dishes:dishes, menufixed: menufixed, info:info, address:address, payment: payment, subtotal:subTotal, menu_price: menuprice, shipping_fee:shipping_fee, discount_percent:discountPercent, discount_price:discountPrice, total_order:totalOrder, note: info.note }

      if(this.payment == 'Carta'){         
        //var stripe = window.Stripe(this.shop.options.payment.pk);
        localStorage.setItem('data', JSON.stringify(orderData));
        this.$router.push('/stripepay')

        // let dataPay = JSON.parse(localStorage.getItem('data'))
        // this.axios.post('/webapi/Orders/pay', {data: dataPay}).then((response)=>{
        //   return response.data.data
        // }).then((session)=>{
        //   return stripe.redirectToCheckout({ sessionId: session.id });
        // }).then((result)=>{
         
        //   if (result.error) {
        //     alert(result.error.message);
        //   }
        // }).catch((error) => {
        //   console.log("Error:", error);
        // });

      
      }else if(this.payment == 'contanti'){
        this.createOrder(orderData)
      }else{
        Dialog({ message: 'Stato errore, si prega di riprovare' });
        return false
      }
       setTimeout(()=>{this.disabledButton = false},30000)
      
    },
    createOrder(orderData){
      this.axios.post('/webapi/Orders/createOrder', {data:orderData}).then((res)=>{
        if(res.data.code != 200){
          Dialog.alert({
            message: res.data.message,
            confirmButtonText: 'OK'
          })
        }else{
          this.$store.commit('emptyCart')
           this.$router.push({
              path: '/success/'+res.data.data.order_number
           })
        }

      })
    }
  },
};
</script>
<style>
.checkout-container-wrapper {
  width: 100%;
  overflow-x: hidden;
  background: #f7f7f7;
  padding-bottom: 100px;
}
.checkout-container {
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 100px;
}
.checkout-content-box {
  padding: 5px;
  background: #f7f7f7;
}

.checkout-content {
  width: 68%;
  min-height: 300px;
  float: left;
}
.checkout-content .van-field__label {
  text-align: right;
}
.checkout-sidebar {
  display: block;
  width: 30%;
  float: right;
  background: #ffffff;
  padding-bottom: 50px;
  position: relative;
}

.checkout-services-wrapper {
  clear: both;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  background: #ffffff;
  padding: 15px 0;
}

.checkout-services-box {
  width: 46%;
  height: 60px;
  display: inline-block;
  text-align: center;
  border: 1px solid #e5e5e5e5;
  margin: 5px;
  padding: 20px 0;
  border-radius: 15px;
  position: relative;
}
.checkout-services-box .van-icon {
  color: red;
  position: absolute;
  top: 20px;
  right: 20px;
}
.service-icon {
  position: relative;
  width: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.service-icon img {
  width: 100%;
}
.active {
  border: 2px solid red;
}
.send-order-button {
  font-size: 1.3em;
  position: fixed;
  bottom: 0;
  right: 0;
}
.address-restaurant {
  font-size: 16px;
}
.title-address {
  font-size: 1.2em;
  width: 100%;
  padding: 20px 0px 5px 20px;
  text-align: center;
}
.address-form {
  background: #ffffff;
  margin-top: 5px;
}
@media only screen and (max-width: 978px) {
  .checkout-sidebar {
    width: 45%;
    float: left;
    margin-left: 10px;
  }
  .checkout-content {
    width: 53%;
  }
}

@media only screen and (max-width: 628px) {
  .checkout-sidebar,
  .checkout-content {
    width: 100%;
    margin: 0px 0 5px 0;
  }

  .send-order-button {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
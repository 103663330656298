<template>
      <div class="hours-selection">
            <div class="closed-notice"  v-if="serviceTime == 0">
                  <div class="closed-img"><img src="../../assets/img/closed.png"></div>
                  <div>
                        Spiacenti, abbiamo già terminato l’attività per oggi.
                  </div>
            </div>
            <div style="margin-bottom: 15px;" v-if="serviceTime != 0">Scegli l’orario di consegna ( può subire variazioni)</div>
            <ul>
                  <li @click="selected(hours)" v-for="(hours, index) in serviceTime" :key="index">{{hours}}</li>
            </ul>
      </div>
</template>
<script>
export default {
      name: 'OpenHours',
      props:['dayOpenHours', 'order_interval_minutes', 'order_before_minutes'],
      data(){
            return {
                  serviceTime: []
            }
      },
      created(){
             this.getTodayOpenHours()
      },
      methods:{
            selected(time){
                  this.$parent.$parent.selectedTime(time)
            },
            getTodayOpenHours(){
                   this.serviceTime = []
                   var now = new Date()
                   var year = now.getFullYear()
                   var month = now.getMonth() +1
                   var date = now.getDate()
                   var currentTime = now.getTime()
                   var today = now.getDay() == 0 ? 6 : now.getDay() -1

                   var launchStart = new Date(this.dayOpenHours[today].launch.start).getHours() + ':'+ new Date(this.dayOpenHours[today].launch.start).getMinutes()+':00'

                   var launchEnd = new Date(this.dayOpenHours[today].launch.end).getHours() + ':'+ new Date(this.dayOpenHours[today].launch.end).getMinutes()+':00'
                   
                   var dinnerStart = new Date(this.dayOpenHours[today].dinner.start).getHours() + ':'+ new Date(this.dayOpenHours[today].dinner.start).getMinutes()+':00'

                   var dinnerEnd = new Date(this.dayOpenHours[today].dinner.end).getHours() + ':'+ new Date(this.dayOpenHours[today].dinner.end).getMinutes()+':00'


                  var launchStartTimestamp =  new Date(year +'/'+ month+ '/' + date + ' ' + launchStart).getTime()
                  var launchEndTimestamp = new Date(year +'/'+ month+ '/' + date + ' ' + launchEnd).getTime()
                  var DinnerStartTimestamp =  new Date(year +'/'+ month+ '/' + date + ' ' + dinnerStart).getTime()
                  var DinnerEndTimestamp =  new Date(year +'/'+ month+ '/' + date + ' ' + dinnerEnd).getTime()

                   var timeDistance =  this.order_interval_minutes * 60000 

                   for(var l = launchStartTimestamp; l < launchEndTimestamp; l +=  timeDistance){
                         if(currentTime < l){
                             var d = new Date(l)
                             this.serviceTime.push(d.getHours() +':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()) 
                         }
                                             
                   }
                   
                   for(var i = DinnerStartTimestamp; i < DinnerEndTimestamp; i +=  timeDistance){
                         if(currentTime < i){
                             var d = new Date(i)
                             this.serviceTime.push(d.getHours() +':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()) 
                         }
                                             
                   } 

                        var order_before = this.order_before_minutes * 60000 + currentTime

                         for(var t = 0; t < this.serviceTime.length; t++){
                               var cTime = new Date(year +'/'+ month+ '/' + date + ' ' + this.serviceTime[t]+':00').getTime()
                               if(cTime < order_before ){
                                     this.serviceTime.splice(0, 1)
                               }
                         }
              
            }
      }
}
</script>
<style>
 .hours-selection ul li{
       display: block;
       float: left;
       border: 1px solid #454545;
       margin: 0 5px 10px;
       padding: 10px 15px;
 }
 .closed-img img{
       width: 200px;
 }
 .closed-notice{
       text-align: center;
 }
</style>
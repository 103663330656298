<template>
  <div class="distance-container">
    <h2>Indirizzo di consegna</h2>
    <van-form @submit="calculateDistance">
      <van-cell-group>
        <van-field label-align="right"  label="Via" v-model="address.street" :rules="[{ required: true, message: 'campo richiesta' }]" />
        <van-field label-align="right" label="N.civico" v-model="address.number" :rules="[{ required: true, message: 'campo richiesta' }]" />
        <van-field label-align="right" label="Citofono" v-model="address.intercom"  />
        <van-field label-align="right" label="Città" v-model="address.city" :rules="[{ required: true, message: 'campo richiesta' }]" />
        <van-field label-align="right" label="Cap" v-model="address.zip_code" :rules="[{ required: true, message: 'campo richiesta' }]" />
      </van-cell-group>

      <div class="distance-submit-button">
        <van-button round type="primary" size="large" native-type="submit">Verifica distanza di consegna</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  name:'CalculateDistance',
  data(){
        return {
              shop: [],
              address:{
                    street: '',
                    number: '',
                    intercom: '',
                    city:'',
                    zip_code:''
              },
             distanceIsService: false
        }
  },
  created(){
     this.fetchData()
  },
  methods: {
    fetchData() {
      this.axios.get("/webapi/shopInfo/getShopInfoOptions").then((res) => {
        this.shop = res.data.data
      });
    },
    calculateDistance(){
        const  _this = this
        const latLng =  _this.shop.lat_lng.split(',')
        var lat = parseFloat(latLng[0])
        var lng = parseFloat(latLng[1])
        var origin = new google.maps.LatLng(lat, lng)
        var service = new google.maps.DistanceMatrixService();
        var destination = this.address.street 
                        + ', ' +this.address.number
                        + ', ' +this.address.zip_code
                        + ', ' +this.address.city
        service.getDistanceMatrix({
          origins: [origin],
          destinations: [destination],
          travelMode: 'DRIVING',
          avoidHighways: false,
		      avoidTolls: false,
      }, function(response, status){
        const distance = response.rows[0].elements[0].distance   
         _this.distanceIsService = (_this.shop.options.delivery.max_distance * 1000) > distance.value
          if(_this.distanceIsService){
            _this.address.distance = distance.value
            _this.$parent.$parent.updateAddress(_this.address)
          }else{
            Dialog.alert({
              title: 'Avviso',
              message: 'Siamo spiacenti, l’indirizzo è fuori il range di consegna',
              confirmButtonText: 'OK'
            })
          }
      })
    }
  },
};
</script>
<style>
.distance-container {
  padding: 35px 15px 15px;
}
.distance-container h2 {
  width: 100%;
  text-align: center;
  padding: 10px 0 20px;
  font-size: 1.3em;
}
.distance-submit-button {
  width: 100%;
  margin-top: 60px;
}
</style>